.App {
  text-align: center;
  max-width:100%;
  overflow-x: hidden;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-container {
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 5vh;
  margin-bottom: 5vh;
}

img {
  width: 100%;
  height: auto;
}

.footer-style {
  position:relative;
  bottom: 0;
  padding-top: 1vh;
  width: 100% !important;
  height: 15vh !important ;
  background: rgb(73, 140, 240);
}

.grid-items-style {
  justify-content: center; 
  padding-top:8vh;
}

.center-div {
  width: 100% !important;
}

.navbar-style {
  width: 100% !important;
}

.button-style {
  padding: 2vh;
}

.text-box-container{
  width: 70vh;
  display: flex;
  text-align: left;
}
.center-textbox{
  display: flex;
  justify-content: center;
}

@media (min-height: 1366px) {
  .footer-style{
    position: absolute;
  }
}

@media (max-width: 656px) {
  .image-container{
    display: none;
  }
  .h2-style {
    margin-top: 10vh; /* Ikke i bruk */
  }
  .footer-style {
    position:relative;
    min-height:10%;
    height:auto !important;
    height:100%; 
  }
  .grid-items-style {
    padding-top:4vh;
    padding-bottom: 4vh;
    padding-left: 1vh;
    padding-right: 1vh;
  }
}
@media (max-width: 400px) {
  .footer-style{
    position: relative;
    min-height:10%;
    height:auto !important;
    height:100%; 
  }
}